import {
  WppButton,
  WppTypography,
  WppDivider,
  WppActionButton,
  WppIconTriangleFill,
  WppMenuContext,
  WppInlineMessage,
  WppIconExternalLink,
  WppTag,
  WppBreadcrumb,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import { AxiosError } from 'axios'
import { format } from 'date-fns'
import { useEffect, useCallback, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as zod from 'zod'

import { usePatchTaskApi } from 'api/canvas/mutation/usePatchTaskApi'
import { useMembersListApi } from 'api/projects/queries/useMembersListApi'
import { useProjectApi } from 'api/projects/queries/useProjectApi'
import { Flex } from 'components/common/flex/Flex'
import { SideModalContentSkeleton } from 'components/common/sideModalSkeleton/SideModalContentSkeleton'
import { tableActions } from 'components/common/table'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { SvgWrikeLogo } from 'components/svg/WrikeLogo'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { TableKey } from 'constants/table'
import { useForm } from 'hooks/form/useForm'
import { useAssignMember } from 'hooks/useAssignMember'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import { projectDateFormat } from 'pages/components/projectModal/utils'
import { ErrorState } from 'pages/components/templatePreviewModal/utils'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson'
import { SelectDateInline } from 'pages/project/components/canvas/components/selectDateInline/SelectDateInline'
import {
  getDate,
  normalizeUserObj,
  ResponsibleUser,
} from 'pages/project/components/canvas/components/selectPerson/utils'
import { SelectPersonInline } from 'pages/project/components/canvas/components/selectPersonInline/SelectPersonInline'
import { invalidateCanvas } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { TaskStatusChangeDropdown } from 'pages/project/components/tasks/components/changeStatus/TaskStatusChangeDropdown'
import { taskTargetTypeMap } from 'pages/project/components/tasks/components/taskDetailsModal/TaskDetailsModal'
import { TaskLocation } from 'pages/project/components/tasks/components/taskDetailsModal/TaskLocation'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { queryClient } from 'providers/osQueryClient/utils'
import { MayBeNull } from 'types/common/utils'
import { Task, TaskStatus } from 'types/projects/tasks'
import { isEqualEmails } from 'utils/common'
import { NiceModalWrappedProps } from 'utils/createNiceModal'
import { formatDate } from 'utils/dateFormat'
import { routesManager } from 'utils/routesManager'

interface Props extends NiceModalWrappedProps {
  task: MayBeNull<Task>
  isLoading: boolean
  canEdit: boolean
  isError: boolean
  error: AxiosError<unknown, any> | null
  isWrikeEnabled?: MayBeNull<boolean>
}

const taskValidationSchema = zod
  .object({
    name: zod
      .string()
      .min(1, 'Task name should contain at least 1 character')
      .max(256, 'Task name exceeded the maximum number of characters'),
    description: zod.string().max(1000, 'Description should have 1000 characters at most').optional(),
  })
  .passthrough()

interface BaseFormValues {
  assignUser: MayBeNull<ResponsibleUser>
  name?: string
  description?: string
  startDate?: string
  endDate?: string
  status: TaskStatus
}

const defaultValues: BaseFormValues = {
  assignUser: null,
  description: '',
  name: '',
  status: TaskStatus.TO_DO,
}

const WrikeInfo = ({ task }: { task: Task }) => {
  const { t } = useTranslation()
  const {
    osContext: {
      userDetails: { dateLocale },
    },
  } = useOs()

  return (
    <>
      <WppDivider />
      <Flex align="center">
        <SvgWrikeLogo className="wpp-spacing-8-right" />
        <Flex direction="column">
          <WppTypography type="s-body" className={styles.greyColor1000}>
            {t('project.tasks.edit_modal.wrike_last_sync')}{' '}
            {formatDate({
              dateString: task?.wrikeLastSyncedAt!,
              dateLocale,
              relativeFormatting: true,
              relativeSameDay: true,
            })}
          </WppTypography>

          {task.targetType === 'external' && (
            <WppTypography type="s-body" className={styles.greyColor800}>
              {t('project.tasks.edit_modal.created_on_wrike')}
            </WppTypography>
          )}
        </Flex>
      </Flex>
    </>
  )
}

export const TaskDetailsModalView = ({
  task,
  isLoading,
  canEdit,
  onClose,
  onCloseComplete,
  isOpen,
  id,
  isError,
  error,
  isWrikeEnabled,
}: Props) => {
  const { showToast } = useToast()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const context = useProject()

  const { data: project } = useProjectApi({
    params: { id: task?.projectId! },
    enabled: !context?.project && !!task?.projectId,
  })

  const { data: members, isLoading: isMembersLoading } = useMembersListApi({
    params: { id: task?.projectId! },
    enabled: !!task?.projectId,
  })

  const { mutateAsync: handleUpdateTask } = usePatchTaskApi()

  const form = useForm({ defaultValues, validationSchema: taskValidationSchema })
  const assignMember = useAssignMember(task?.assignUser, task?.projectId, isMembersLoading)

  const {
    handleSubmit,
    formState: { isSubmitting },
    setValues,
    watch,
    setValue,
  } = form

  const [startDate, endDate, status, assignUser] = watch(['startDate', 'endDate', 'status', 'assignUser'])

  const onUserChange = useCallback(
    (person: ResponsibleUser) => {
      if (isEqualEmails(person.email, assignUser?.email)) {
        setValue('assignUser', null)
      } else {
        setValue('assignUser', person)
      }
    },
    [assignUser?.email, setValue],
  )

  useEffect(() => {
    if (task && canEdit) {
      const { name, description, startDate, endDate, assignUser, status } = task
      const member = members.find(member => isEqualEmails(member.email, assignUser))
      setValues({
        name,
        assignUser: member ? normalizeUserObj(member) : null,
        description: description || '',
        startDate,
        endDate,
        status,
      })
    }
  }, [canEdit, members, setValues, task])

  const onSubmit = handleSubmit(async ({ assignUser, startDate, endDate, status, description, name }) => {
    try {
      if (!task) return
      await handleUpdateTask({
        id: task.id,
        assignUser: assignUser?.email || null,
        startDate: startDate || null,
        endDate: endDate || null,
        status,
        description: description?.trim() || null,
        name: name?.trim() || null,
      })

      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_TASKS_LIST])
      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_TASK])
      queryClient.invalidateQueries([ApiQueryKeys.TASKS_FETCHER])
      tableActions.reload([TableKey.TASKS_LIST])

      await invalidateCanvas(!assignUser?.isMember)
      onClose()
    } catch (e) {
      showToast({
        type: 'error',
        message: t('common.generic_error'),
      })
      console.error(e)
    }
  })

  const handleUpdateDates = (dates: Date[]) => {
    const stringDates = dates.map(date => format(date, projectDateFormat))
    const datesMapped = { ...getDate(stringDates) }

    setValue('startDate', datesMapped.startDate || undefined)
    setValue('endDate', datesMapped.endDate || undefined)
  }

  const showWarningArhivedStatus = useMemo(
    () => task?.status !== TaskStatus.ARCHIVED && status === TaskStatus.ARCHIVED,
    [status, task?.status],
  )

  const handleRouteChange = (event: CustomEvent) => {
    onClose()
    navigate(routesManager.project.tasks.getURL({ id: event.detail.path }))
  }

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        id={id}
        size="m"
        data-testid="task-view-modal"
      >
        {isLoading ? (
          <SideModalContentSkeleton />
        ) : (
          <>
            <WppTypography slot="header" type="2xl-heading">
              {t('project.tasks.details_modal.title')}
            </WppTypography>
            <Flex slot="body" direction="column" gap={24} className={styles.tasksContainer}>
              <>
                {isError ? (
                  <ErrorState error={error} />
                ) : (
                  task &&
                  (canEdit ? (
                    <>
                      <WppBreadcrumb
                        items={[
                          { label: task.projectName, path: task.projectId },
                          { label: task.name, path: task.id },
                        ]}
                        onWppChange={handleRouteChange}
                        maxLabelLength={Number.POSITIVE_INFINITY}
                      />
                      <Flex direction="column" gap={12}>
                        <FormInput
                          name="name"
                          data-testid="task-name-input"
                          labelConfig={{ text: t('project.tasks.edit_modal.task_name_label') }}
                          placeholder={t('project.tasks.edit_modal.task_name_placeholder')!}
                          required
                        />
                        <Flex gap={16} align="center">
                          <Flex align="center">
                            <WppMenuContext data-testid="status-change">
                              <WppActionButton variant="secondary" slot="trigger-element">
                                <WppIconTriangleFill slot="icon-end" className={styles.triangleIcon} />
                                {t(`project.tasks.status.${status}`)}
                              </WppActionButton>
                              <div>
                                <TaskStatusChangeDropdown
                                  onChange={status => setValue('status', status)}
                                  selectedStatus={status}
                                  showConfirm={false}
                                />
                              </div>
                            </WppMenuContext>
                          </Flex>

                          <SelectPersonInline
                            selectedId={assignUser?.id}
                            onChange={onUserChange}
                            projectId={task.projectId}
                            withWrike={project?.wrike?.isConnected}
                          >
                            <ResponsiblePerson
                              assignMember={assignUser!}
                              size="xs"
                              data-testid="task-item-assignee"
                              showName
                            />
                          </SelectPersonInline>
                          <SelectDateInline
                            startDate={startDate}
                            endDate={endDate}
                            data-testid="task-item-dates"
                            onChange={handleUpdateDates}
                          />
                        </Flex>
                      </Flex>
                      {showWarningArhivedStatus && (
                        <WppInlineMessage
                          size="m"
                          message={t('project.tasks.edit_modal.archive_information')!}
                          type="information"
                        />
                      )}
                      <Flex direction="column" gap={24}>
                        <FormTextareaInput
                          name="description"
                          labelConfig={{ text: t('project.tasks.edit_modal.task_description_label') }}
                          placeholder={t('project.tasks.edit_modal.task_description_placeholder')}
                          warningThreshold={980}
                          charactersLimit={1000}
                          data-testid="modal-task-description"
                        />
                        <WppDivider />
                        <Flex gap={24} direction="column">
                          <Flex gap={20} align="center">
                            <Flex inline direction="column" className={styles.icon} justify="center" align="center">
                              {taskTargetTypeMap[task.targetType].icon}
                            </Flex>
                            <Flex direction="column">
                              <WppTypography type="xs-midi" className={styles.greyColor800}>
                                {t('project.tasks.details_modal.task_type_label')}
                              </WppTypography>
                              <WppTypography type="s-body">{t(taskTargetTypeMap[task.targetType].name)}</WppTypography>
                            </Flex>
                          </Flex>
                          <TaskLocation location={task.location} />
                        </Flex>
                      </Flex>
                      {isWrikeEnabled && <WrikeInfo task={task} />}
                    </>
                  ) : (
                    <>
                      <WppBreadcrumb
                        items={[
                          { label: task.projectName, path: task.projectId },
                          { label: task.name, path: task.id },
                        ]}
                        onWppChange={handleRouteChange}
                        maxLabelLength={Number.POSITIVE_INFINITY}
                      />
                      <Flex direction="column" gap={12}>
                        <WppTypography type="xl-heading">{task.name ?? ''}</WppTypography>
                        <Flex gap={24} align="center">
                          <WppTag variant="neutral" label={t(`project.tasks.status.${task.status}`)!} />
                          <ResponsiblePerson
                            assignMember={assignMember}
                            size="xs"
                            data-testid="task-item-assignee"
                            showName
                          />
                          <Calendar startDate={task?.startDate} endDate={task.endDate} data-testid="task-item-dates" />
                        </Flex>
                      </Flex>
                      {task.description && (
                        <Flex gap={4} direction="column">
                          <WppTypography type="s-strong" className={styles.greyColor800}>
                            {t('project.tasks.details_modal.description_label')}
                          </WppTypography>
                          <WppTypography type="s-midi" className={styles.greyColor1000}>
                            {task.description}
                          </WppTypography>
                        </Flex>
                      )}
                      <WppDivider />
                      <Flex gap={24} direction="column">
                        <Flex gap={20} align="center">
                          <Flex inline direction="column" className={styles.icon} justify="center" align="center">
                            {taskTargetTypeMap[task.targetType].icon}
                          </Flex>
                          <Flex direction="column">
                            <WppTypography type="xs-midi" className={styles.greyColor800}>
                              {t('project.tasks.details_modal.task_type_label')}
                            </WppTypography>
                            <WppTypography type="s-body">{t(taskTargetTypeMap[task.targetType].name)}</WppTypography>
                          </Flex>
                        </Flex>
                        <TaskLocation location={task.location} />
                      </Flex>

                      {isWrikeEnabled && <WrikeInfo task={task} />}
                    </>
                  ))
                )}
              </>
            </Flex>
            <Flex slot="actions" justify="between" gap={12}>
              {project?.wrike?.isConnected && task?.wrikeTaskUrl ? (
                <WppActionButton
                  data-testid="task-view-wrike-link"
                  variant="primary"
                  className={styles.externalLink}
                  onClick={() => {
                    window.open(task.wrikeTaskUrl!, '_blank')
                  }}
                >
                  {t('project.tasks.details_modal.view_on_wrike')}
                  <WppIconExternalLink slot="icon-end" />
                </WppActionButton>
              ) : (
                <div />
              )}
              {canEdit ? (
                <Flex gap={12}>
                  <WppButton variant="secondary" size="m" onClick={onClose}>
                    {t('common.btn_cancel')}
                  </WppButton>
                  {!isError && (
                    <WppButton variant="primary" size="m" type="submit" loading={isSubmitting}>
                      {t('common.btn_save')}
                    </WppButton>
                  )}
                </Flex>
              ) : (
                <WppButton variant="primary" size="m" onClick={onClose}>
                  {t('common.btn_done')}
                </WppButton>
              )}
            </Flex>
          </>
        )}
      </SideModal>
    </FormProvider>
  )
}
