import clsx from 'clsx'
import { RefObject, useEffect, useRef } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import styles from 'pages/project/components/canvas/linearCanvas/components/stickySubheader/StickySubheader.module.scss'

interface Props {
  columns: { name: string; id: string }[]
  headerHidden: boolean
  containerRef: RefObject<HTMLDivElement>
  columnClass?: string
}

export const StickySubheader = ({ columns, headerHidden, containerRef, columnClass }: Props) => {
  const subheaderRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const scrollContainer = containerRef?.current
    const scrollHandler = () => {
      const scrollLeft = scrollContainer?.scrollLeft || 0

      if (subheaderRef.current) {
        subheaderRef.current.style.left = `${0 - scrollLeft}px`
      }
    }

    scrollContainer?.addEventListener('scroll', scrollHandler)

    return () => {
      scrollContainer?.removeEventListener('scroll', scrollHandler)
    }
  }, [containerRef])

  return (
    <Flex className={clsx(styles.stickySubheader, { [styles.headerHidden]: headerHidden })} ref={subheaderRef}>
      <Flex className={styles.stickySubheaderWrapper}>
        {columns.map(({ id, name }) => {
          return (
            <Truncate
              className={clsx(styles.stickySubheaderColumn, columnClass)}
              lines={2}
              type="m-strong"
              data-testid="phase-name"
              key={id}
            >
              {name}
            </Truncate>
          )
        })}
      </Flex>
    </Flex>
  )
}
