import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ActivityPostItemRequest } from 'api/canvas/fetchers/createActivity'
import { useCreateActivity } from 'api/canvas/mutation/useCreateActivity'
import { usePatchActivityApi } from 'api/canvas/mutation/usePatchActivityApi'
import { Flex } from 'components/common/flex/Flex'
import { FormDatepicker } from 'components/form/formDatepicker/FormDatepicker'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { useForm } from 'hooks/form/useForm'
import { useToast } from 'hooks/useToast'
import { projectDateFormat } from 'pages/components/projectModal/utils'
import styles from 'pages/project/components/canvas/components/editPhaseModal/EditPhaseModal.module.scss'
import { SelectPerson } from 'pages/project/components/canvas/components/selectPerson/SelectPerson'
import { getDate, useGetBaseFormValues } from 'pages/project/components/canvas/components/selectPerson/utils'
import { START_POSITION } from 'pages/project/components/canvas/fluidCanvas/utils'
import {
  activityValidationScheme,
  invalidateCanvas,
} from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { ProcessType } from 'types/projects/projects'
import { TaskStatus } from 'types/projects/tasks'
import { ActivityItem } from 'types/projects/workflow'
import { makeStringShorter } from 'utils/common'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  projectId: string
  phaseId?: string
  activity?: ActivityItem
  isDisabled?: boolean
  selectedCanvas?: ProcessType
}

const initialValues = {
  name: '',
  description: '',
}

const AddEditActivityModal = ({
  projectId,
  phaseId,
  isOpen,
  activity,
  onClose,
  onCloseComplete,
  isDisabled,
  selectedCanvas = ProcessType.LINEAR,
}: Props) => {
  const defaultValues = {
    ...useGetBaseFormValues(activity ?? initialValues),
    description: activity?.description || '',
  }

  const isNew = !activity
  const form = useForm({ defaultValues, validationSchema: activityValidationScheme })

  const { t } = useTranslation()
  const { showToast } = useToast()
  const { mutateAsync: handleCreateActivity } = useCreateActivity()
  const { mutateAsync: handleUpdateActivity } = usePatchActivityApi()

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form

  const onSubmit = handleSubmit(async ({ dueDate, name, assignUser: users, description: newDescription, status }) => {
    try {
      const assignUser = users[0]

      const dates = dueDate ? getDate(dueDate) : {}

      const formattedDates = {
        startDate: dates.startDate ? format(new Date(dates.startDate), projectDateFormat) : null,
        endDate: dates.endDate ? format(new Date(dates.endDate), projectDateFormat) : null,
      }

      if (isNew) {
        let body: ActivityPostItemRequest = {
          ...formattedDates,
          name: name?.trim() || '',
          assignUser: assignUser?.email || null,
          description: newDescription?.trim() || null,
          phaseId,
          projectId,
          taskStatus: status,
        }

        if (selectedCanvas === ProcessType.FLUID) {
          body = {
            ...body,
            coordinateX: START_POSITION.x,
            coordinateY: START_POSITION.y,
          }
        }

        await handleCreateActivity(body)
        showToast({
          type: 'success',
          message: t('project.canvas.toast.add_an_activity', { query: makeStringShorter(name) }),
        })
      } else {
        const body = {
          ...formattedDates,
          name: name?.trim() || '',
          assignUser: assignUser?.email || null,
          description: newDescription?.trim() || null,
          taskStatus: status,
        }

        await handleUpdateActivity({
          id: activity?.id!,
          ...body,
        })

        showToast({
          type: 'success',
          message: t('project.canvas.toast.update_activity', { query: makeStringShorter(name) }),
        })
      }

      await invalidateCanvas(!assignUser?.isMember)
      onClose()
    } catch (e) {
      const toastMsg = isNew
        ? 'project.canvas.toast.failed_operation_create'
        : 'project.canvas.toast.failed_operation_edit'
      showToast({ type: 'error', message: t(toastMsg, { query: 'activity' }) })
      console.log(e)
    }
  })

  const statusOptions = useMemo(() => {
    return Object.entries(TaskStatus).map(([key, value]) => ({
      value: key,
      label: t(`project.tasks.status.${value}`),
    }))
  }, [t])

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={onCloseComplete}
        size="m"
        data-testid="activity-modal"
      >
        <WppTypography slot="header" type="2xl-heading">
          {isNew ? t('product.activity.create_modal_title') : t('product.activity.edit_modal_title')}
        </WppTypography>
        <Flex slot="body" direction="column" gap={24}>
          <FormInput
            name="name"
            labelConfig={{ text: t('product.activity.field_name') }}
            placeholder={t('product.activity.field_name_placeholder')!}
            required
            disabled={isDisabled}
            data-testid="activity-modal-name"
          />
          <FormTextareaInput
            name="description"
            labelConfig={{ text: t('product.activity.description') }}
            placeholder="Describe activity"
            warningThreshold={980}
            charactersLimit={1000}
            disabled={isDisabled}
            data-testid="modal-activity-description"
          />

          <SelectPerson disabled={isDisabled} />
          <Flex justify="between" gap={20} align="end">
            <FormDatepicker
              className={styles.datePicker}
              name="dueDate"
              placeholder="Start date | End date"
              labelConfig={{ text: t('product.field_due_date') }}
              range
              disabled={isDisabled}
            />
          </Flex>

          <FormSelect
            name="status"
            type="single"
            data-testid="status-select"
            options={statusOptions}
            labelConfig={{ text: t('product.status_label') }}
            required
            withSearch
          />
        </Flex>
        <Flex slot="actions" justify="end" gap={12}>
          <WppButton variant="secondary" size="m" onClick={onClose}>
            {t('common.btn_cancel')}
          </WppButton>
          <WppButton variant="primary" size="m" type="submit" loading={isSubmitting} disabled={isDisabled}>
            {isNew ? t('common.btn_add') : t('common.btn_save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showAddEditActivityModal } = createProjectModal<Props>(
  AddEditActivityModal,
  'add-edit-activity-modal',
)
