import { MayBeNull } from '@wpp-open/core'

import { ConnectionType } from 'pages/project/components/canvas/fluidCanvas/utils'
import { NativeAppType, NativeAppVersionType } from 'types/products/nativeApp'
import { TaskStatus } from 'types/projects/tasks'

export interface Workflow {
  phases: Phase[]
}

export interface Phase {
  id: string
  name: string
  status: PhaseStatus
  orderNumber: number
  description?: string
  startDate?: string
  endDate?: string
  assignUser?: string
  phaseItems: PhaseItem[]
}

export enum PhaseItemType {
  Application = 'application',
  Activity = 'activity',
  Task = 'task',
}

export enum PhaseStatus {
  TO_DO = 'TO_DO',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export interface ExternalLink {
  url: string
  alias: string
  id?: string
  createdAt: string
}

export interface ApplicationItem {
  id: string
  type: NativeAppType
  externalAppOriginName: string
  externalAppId: string
  externalAppName: string
  externalAppOriginId?: string
  logoUrl: string
  agencyExternalId: string
  name?: string
  startDate?: string
  endDate?: string
  assignUser?: string
  isOutdated?: boolean
  isAvailable?: boolean
  isDeleted?: boolean
  config?: ItemAppConfig
  orderNumber?: number
  presentationSlideId?: string
  task: MayBeNull<TaskItem>
  hidden: boolean
  externalAppVersions: ExternalAppVersion[]
  externalAppVersionId?: string
  description?: string
  appInstanceId: string
}

export interface ExternalAppVersion {
  id: string
  name: string
  versionType: NativeAppVersionType
  config: { url?: string }
  dataContext: string[]
}

export interface ActivityItem {
  id: string
  items: ActivityApplicationItem[]
  name?: string
  description?: string
  startDate?: string
  endDate?: string
  assignUser?: string
  externalLinks?: ExternalLink[]
  task: TaskItem
  files: ActivityFiles[]
}

export interface ActivityFiles {
  id: string
  key: string
  name: string
  size: string
  fileType: string
  scope: FileScope
  createdByEmail: string
}

export enum FileScope {
  PROJECT = 'PROJECT',
  ITEM = 'ITEM',
}

export interface ActivityApplicationItem {
  id: string
  orderNumber: number
  application: ApplicationItem
  activityItemId: string
}

interface TaskItem {
  orderNumber: number
  id: string
  status: TaskStatus
}

export interface PhaseItem<T = ApplicationItem | ActivityItem> {
  id: string
  phaseId: string
  itemType: PhaseItemType
  orderNumber: number
  item: T
}

export interface ItemAppConfig {
  bundle_url: string
  container_id: string
  os_route: string
  required_hierarchy: string[]
  view_permission_name: string
  window_library_name: string
}

enum AnchorSide {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}

export interface FluidWorkflow {
  containers: Container[]
  connections: IConnection[]
  items: (ApplicationItem | ActivityItem)[]
}

export enum ContainerType {
  ROOT = 'ROOT',
  NESTED = 'NESTED',
}

export interface Container {
  id: string
  itemId: string
  itemType: PhaseItemType
  coordinateX: number
  coordinateY: number
  containerType: ContainerType

  rootContainerId?: string
  orderNumberInRoot?: number
}

export interface IConnection {
  id: string
  type: ConnectionType
  sourceId: string
  sourceAnchorSide: AnchorSide
  targetId: string
  targetAnchorSide: AnchorSide
}
