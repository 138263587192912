import {
  WppActionButton,
  WppCard,
  WppDivider,
  WppIconEnter,
  WppIconGear,
  WppIconMore,
  WppListItem,
  WppMenuContext,
  WppTag,
  WppTooltip,
  WppTypography,
  WppIconRemoveCircle,
  WppIconEyeOff,
  WppIconEyeOn,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { forwardRef, PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useDeleteApplicationApi } from 'api/canvas/mutation/useDeleteApplicationApi'
import { showDeleteModal } from 'components/common/deleteModal/DeleteModal'
import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { useAppLaunchErrors } from 'hooks/application/useAppErrors'
import { useOpenApplication } from 'hooks/application/useOpenApplication'
import { useAssignMember } from 'hooks/useAssignMember'
import { useToast } from 'hooks/useToast'
import { ApplicationLogo } from 'pages/components/applicationLogo/ApplicationLogo'
import { AppLaunchWarnings } from 'pages/project/components/canvas/components/appLaunchWarnings/AppLaunchWarnings'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson'
import { SelectDateInline } from 'pages/project/components/canvas/components/selectDateInline/SelectDateInline'
import { ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { SelectPersonInline } from 'pages/project/components/canvas/components/selectPersonInline/SelectPersonInline'
import styles from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AppActivityItem.module.scss'
import { invalidateCanvas, useUiPartEnabled } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { TaskStatusChangeDropdown } from 'pages/project/components/tasks/components/changeStatus/TaskStatusChangeDropdown'
import { StatusText } from 'pages/project/components/tasks/components/statusText/StatusText'
import { NativeAppVersionType } from 'types/products/nativeApp'
import { ProjectPartKey } from 'types/projects/projectViewSettings'
import { TaskStatus } from 'types/projects/tasks'
import { ApplicationItem } from 'types/projects/workflow'
import { capitalizeFirstLetter, makeStringShorter } from 'utils/common'

interface Props {
  application: ApplicationItem
  showAction: boolean
  isDisabled: boolean
  index: number
  changeAssignee: (newAssignee: ResponsibleUser) => Promise<void>
  changeDates: (dates: Date[]) => void
  changeHidden?: (hidden: boolean) => void
  editApp?: () => void
  changeStatus: (status: TaskStatus) => void
  variant?: 'primary' | 'secondary'
  isEditable?: boolean
  isInactive?: boolean
  phaseId?: string
  activityId?: string
  isActivityApplication?: boolean
  isDraggingDisabled?: boolean
}

export const LinearEditableApplication = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      application,
      showAction,
      isDisabled,
      index,
      variant,
      isEditable,
      isInactive,
      phaseId,
      activityId,
      isActivityApplication,
      changeStatus,
      isDraggingDisabled,
      ...rest
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const { changeAssignee, changeDates, editApp, changeHidden } = rest
    const { name, startDate, endDate, externalAppName, task, externalAppVersions, externalAppVersionId } = application
    const assignMember = useAssignMember(application.assignUser)
    const isUIPartEnabled = useUiPartEnabled(isEditable)

    const { showToast } = useToast()
    const { mutateAsync: handleDeleteApplication } = useDeleteApplicationApi()

    const appLaunchErrors = useAppLaunchErrors(application)
    const { handleOpenApplication, handleTrackOpenApplication } = useOpenApplication({ application })

    const handleDelete = async () => {
      try {
        await handleDeleteApplication({ id: application.id })

        await invalidateCanvas()
        showToast({
          type: 'success',
          message: t('project.canvas.toast.delete_app', { query: makeStringShorter(name) }),
        })
      } catch (e) {
        showToast({
          type: 'error',
          message: t('project.canvas.toast.failed_operation_remove', { query: 'application' }),
        })
        console.error(e)
      }
    }

    const showLeanAppWarnings = () => {
      if (!isUIPartEnabled(ProjectPartKey.AppWarning)) {
        return null
      }

      return (
        <AppLaunchWarnings
          application={application}
          className={clsx({ [styles.disabledItem]: isDisabled || isInactive })}
        />
      )
    }

    const selectedVersion = useMemo(
      () => externalAppVersions?.find(version => version.id === externalAppVersionId),
      [externalAppVersionId, externalAppVersions],
    )

    const disableLaunch = useMemo(() => {
      return !!appLaunchErrors.length
    }, [appLaunchErrors.length])

    return (
      <div ref={ref} data-testid={`application-${isActivityApplication ? 'activity' : 'item'}-${application.id}`}>
        <WppCard
          variant={variant}
          className={clsx(styles.itemContainer, {
            [styles.itemContainer]: isActivityApplication,
            [styles.dragItem]: !isDisabled && !isInactive && !isDraggingDisabled,
          })}
          size="s"
        >
          <Flex direction="column" gap={8} className={clsx({ [styles.disabledItem]: isDisabled || isInactive })}>
            <Flex inline direction="column" className={styles.logo} justify="center" align="center">
              <ApplicationLogo logo={application?.logoUrl} />
            </Flex>
            <Flex direction="column">
              <Truncate lines={1} title={name || externalAppName} type="m-strong" data-testid="phase-item-name">
                {name || externalAppName}
              </Truncate>

              {task?.status === TaskStatus.COMPLETED || task?.status === TaskStatus.ARCHIVED ? (
                <StatusText statusKey={task?.status} />
              ) : (
                <div className={styles.statusPlaceholder} />
              )}
            </Flex>
            <WppDivider />

            {showAction && !isInactive ? (
              <Flex align="center" justify="between" gap={16}>
                <Flex gap={4}>
                  <SelectPersonInline selectedId={assignMember?.id} onChange={changeAssignee}>
                    <ResponsiblePerson assignMember={assignMember} size="xs" />
                  </SelectPersonInline>

                  <SelectDateInline startDate={startDate} endDate={endDate} onChange={changeDates} />

                  <WppActionButton
                    onClick={() => changeHidden?.(!application.hidden)}
                    className={styles.btn}
                    data-testid="application-hide-action"
                  >
                    <WppTooltip
                      header="Workflow visibility"
                      className={styles.tooltip}
                      config={{ maxWidth: 'none' }}
                      text={application.hidden ? 'Hidden' : 'Visible'}
                    >
                      {application.hidden ? (
                        <WppIconEyeOff data-testid="application-hide-off" />
                      ) : (
                        <WppIconEyeOn data-testid="application-hide-on" />
                      )}
                    </WppTooltip>
                  </WppActionButton>
                </Flex>
                {selectedVersion && selectedVersion.versionType !== NativeAppVersionType.PRODUCTION && (
                  <WppTooltip text={selectedVersion.name}>
                    <WppTag
                      variant="neutral"
                      label={capitalizeFirstLetter(selectedVersion.versionType.toLowerCase())}
                    />
                  </WppTooltip>
                )}
              </Flex>
            ) : (
              <Flex gap={16} align="center" justify="between">
                <Flex gap={10} align="center">
                  <ResponsiblePerson assignMember={assignMember} size="xs" />
                  <Calendar startDate={startDate} endDate={endDate} />
                </Flex>

                {selectedVersion && selectedVersion.versionType !== NativeAppVersionType.PRODUCTION && (
                  <WppTooltip text={selectedVersion.name}>
                    <WppTag
                      variant="neutral"
                      label={capitalizeFirstLetter(selectedVersion.versionType.toLowerCase())}
                    />
                  </WppTooltip>
                )}
              </Flex>
            )}
          </Flex>
          <Flex slot="actions" align="center" gap={4}>
            {/* @TODO: Need to fix this, create new component or change the way how we show this  */}
            {showLeanAppWarnings()}
            {showAction && (
              <WppMenuContext key={index} dropdownConfig={{ appendTo: () => document.body }}>
                <WppActionButton slot="trigger-element" variant="secondary">
                  <WppIconMore slot="icon-start" direction="horizontal" />
                </WppActionButton>
                <Flex direction="column" className={styles.actionsGap}>
                  <WppListItem
                    onWppChangeListItem={() => {
                      handleOpenApplication()
                      handleTrackOpenApplication(phaseId)
                    }}
                    disabled={disableLaunch}
                    data-testid="context-app-launch"
                  >
                    <WppIconEnter slot="left" />
                    <span slot="label"> {t('project.item.launch_app')}</span>
                  </WppListItem>

                  <WppListItem onWppChangeListItem={editApp} data-testid="context-app-settings">
                    <WppIconGear slot="left" />
                    <WppTypography slot="label" type="s-body">
                      {t('project.item.settings')}
                    </WppTypography>
                  </WppListItem>
                  <div style={{ display: isInactive ? 'none' : 'block' }}>
                    <WppListItem
                      onWppChangeListItem={() =>
                        showDeleteModal({
                          title: t('project.canvas.delete_app_title'),
                          subTitle: t('project.canvas.delete_app'),
                          deleteText: t('project.canvas.delete')!,
                          onDelete: handleDelete,
                        })
                      }
                      data-testid="context-app-remove"
                    >
                      <WppIconRemoveCircle slot="left" />
                      <WppTypography slot="label" type="s-body">
                        {t('common.btn_delete')}
                      </WppTypography>
                    </WppListItem>
                  </div>
                </Flex>

                {task && (
                  /* DO_NOT_DELETE: ternary operator coz error: Can't remove ReactNode. More like Tippy issue, remove node firstly */
                  <div style={{ display: isInactive ? 'none' : 'block' }}>
                    <WppDivider />
                    <TaskStatusChangeDropdown
                      onChange={status => changeStatus(status)}
                      selectedStatus={task?.status}
                      showConfirm={false}
                    />
                  </div>
                )}
              </WppMenuContext>
            )}
          </Flex>
        </WppCard>
      </div>
    )
  },
)
