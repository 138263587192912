// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xZaFa{flex-shrink:0}`, "",{"version":3,"sources":["webpack://./src/pages/components/projectModal/components/appCtxWarningModal/components/AppCtxWarningModal.module.scss"],"names":[],"mappings":"AAAA,OACE,aAAA","sourcesContent":[".appIcon {\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appIcon": `xZaFa`
};
export default ___CSS_LOADER_EXPORT___;
