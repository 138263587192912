import { HierarchyContainerNodeId, HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import { useEffect, useMemo, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

import { HierarchyTreeNode } from 'api/hubs/fetchers/getTenantHierarchy'
import { useGetHubsInfo } from 'api/hubs/queries/useGetHubsInfo'
import { useGetTenantHierarchy } from 'api/hubs/queries/useGetTenantHierarchy'
import { useLocalStorage } from 'hooks/useLocalStorage'
import { useHierarchyFilters } from 'pages/dashboard/utils/hierarchyFilters'
import { LocalStorageKey } from 'types/common/localStorage'

interface Props<T> {
  initState: T
  lsCode: LocalStorageKey.DASHBOARD_FILTERS | LocalStorageKey.DASHBOARD_TASKS_FILTERS
}

const getNodeType = (node: HierarchyTreeNode) =>
  (node?.type === HierarchyCustomNodeType ? node.customTypeName : node?.type)?.toLocaleLowerCase() as string

export const useFilters = <T>({ initState, lsCode }: Props<T>): { isLoading: boolean; state: T } => {
  const {
    osContext: { userDetails },
  } = useOs()

  const hubIdRef = useRef<string | null>(null)
  const [params, setParams] = useSearchParams()

  const [lsFiltersState] = useLocalStorage<T>(`${lsCode}:${userDetails.id}`, {} as T)

  useEffect(() => {
    const { hubId } = Object.fromEntries(params.entries()) as { hubId: string }
    if (!hubId) return
    hubIdRef.current = hubId
    setParams()
  }, [params, setParams])

  const { options, getAllChildren } = useHierarchyFilters()

  const { data: hub, isLoading: isHubLoading } = useGetHubsInfo({
    params: { hubId: hubIdRef.current! },
    enabled: !!hubIdRef.current,
  })

  const { data: hierarchyTree, isLoading: isHierarchyLoading } = useGetTenantHierarchy({
    params: { tenantId: hub?.tenantId! },
    enabled: !!hub,
  })

  const hubFilters = useMemo(() => {
    const node = hierarchyTree?.mapping[HierarchyContainerNodeId]
    const children = node?.children || []

    if (!hub && !hierarchyTree) return null
    const rootNode = hierarchyTree?.mapping[hub?.organizationUnitId!]

    if (rootNode && rootNode.azId === hierarchyTree.rootId) return null

    const nodeType = getNodeType(rootNode!)
    const optionToSelect = options[nodeType]?.find(option => option.value.includes(rootNode?.azId!))
    const workspace = getAllChildren({ [nodeType]: [optionToSelect?.value!] }, children)

    return { [nodeType]: [optionToSelect?.value], workspace }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hub, hierarchyTree])

  return useMemo(
    () => ({
      isLoading: !!hubIdRef.current && (isHubLoading || isHierarchyLoading),
      state: { ...initState, ...(!!hubIdRef.current ? hubFilters : lsFiltersState) },
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hubFilters, hubIdRef.current, isHierarchyLoading, isHubLoading, lsFiltersState],
  )
}
