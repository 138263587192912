import { WppInlineMessage, WppLabel } from '@platform-ui-kit/components-library-react'
import { HierarchyContainerNodeId, HierarchyCustomNodeType } from '@wpp-open/core/types/mapping/common'
import { useOs } from '@wpp-open/react'
import { useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormDatepicker } from 'components/form/formDatepicker/FormDatepicker'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormSelect, FormSelectOption } from 'components/form/formSelect/FormSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { useHierarchy } from 'hooks/useHierarchy'
import styles from 'pages/components/projectModal/components/projectForm/ProjectForm.module.scss'
import { WorkspaceWarning } from 'pages/components/workspaceWarning/WorkspaceWarning'
import { SelectProcessType } from 'pages/project/components/canvas/components/selectProcessType/SelectProcessType'
import { ContextHierarchy, ProjectType } from 'types/projects/projects'
import { capitalizeFirstLetter } from 'utils/common'

export const ProjectForm = ({
  showProcessType = false,
  contextHierarchy = [],
  showWarning = false,
}: {
  showProcessType?: boolean
  showWarning?: boolean
  contextHierarchy?: ContextHierarchy[]
}) => {
  const { t } = useTranslation()
  const { watch, setValue } = useFormContext()
  const {
    osContext: { navigationTree, tenant },
  } = useOs()
  const { hierarchyOrder } = useHierarchy()
  const { mapping } = navigationTree

  const mapNavigationToOption = useCallback(
    (children: string[]) => {
      const options = (children || [])
        .map(id => {
          const { name, type, customTypeName } = mapping[id] || {}

          const handleThisLvl = tenant.hierarchyLevels.some(level => {
            return level.type === (type === HierarchyCustomNodeType ? customTypeName : type)
          })

          if (!handleThisLvl) return null
          return { label: name, value: id }
        })
        .filter(option => option?.label) as FormSelectOption[]

      options.sort((a, b) => a.label?.localeCompare(b.label))

      return options
    },
    [mapping, tenant.hierarchyLevels],
  )

  const formWatch = watch(hierarchyOrder)

  const statusOptions = useMemo(() => {
    return Object.entries(ProjectType).map(([key, value]) => ({
      value: key,
      label: t(`modals.create_project.project_type_${value.toLowerCase()}`),
    }))
  }, [t])

  return (
    <Flex direction="column" gap={69}>
      <Flex direction="column" gap={24}>
        <FormInput
          name="name"
          data-testid="modal-project-name"
          labelConfig={{ text: t('modals.create_project.field_name_label') }}
          placeholder={t('modals.create_project.field_name_placeholder')!}
          required
        />

        <FormSelect
          name="type"
          type="single"
          data-testid="project-type-select"
          options={statusOptions}
          labelConfig={{ text: t('modals.create_project.field_type_label') }}
          required
        />

        {showProcessType && (
          <Flex direction="column" gap={8}>
            <WppLabel
              data-testid="project-process-type-label"
              config={{ text: t('modals.create_project.field_process_type_label') }}
              typography="s-strong"
            />
            <SelectProcessType name="processType" />
          </Flex>
        )}

        <Flex direction="column" gap={24}>
          {showWarning && (
            <WppInlineMessage
              size="m"
              message={t('project.about.incorrect_workspace')}
              type="warning"
              showTooltipFrom={100}
            />
          )}
          <WorkspaceWarning contextHierarchy={contextHierarchy} />
          <div className={styles.grid}>
            {hierarchyOrder.map((hierarchy, index) => {
              const mappingLvl = index < 1 ? HierarchyContainerNodeId : formWatch[index - 1 || 0]
              const optionsList = mapNavigationToOption(mapping[mappingLvl]?.children)
              const isDisabled = index < 1 ? false : !mappingLvl || !optionsList.length

              return (
                <FormSelect
                  key={hierarchy}
                  name={hierarchy}
                  withSearch
                  data-testid={`${hierarchy}-select`}
                  options={optionsList}
                  labelConfig={{
                    text: t(`modals.create_project.field_${hierarchy}_label`, {
                      defaultValue: capitalizeFirstLetter(hierarchy),
                    }),
                  }}
                  placeholder={
                    t(`modals.create_project.field_${hierarchy}_placeholder`, {
                      defaultValue: capitalizeFirstLetter(hierarchy),
                    })!
                  }
                  required
                  toggleSelection
                  disabled={isDisabled}
                  onWppChange={() => hierarchyOrder.slice(index + 1).forEach(el => setValue(el, ''))}
                  className={styles.hierarchySelect}
                />
              )
            })}
            {hierarchyOrder.length % 2 !== 0 && <div />}
            <FormDatepicker
              className={styles.datePicker}
              name="dueDate"
              placeholder={t('modals.create_project.field_due_date_placeholder')}
              labelConfig={{ text: t('modals.create_project.field_due_date_label') }}
              range
            />
          </div>
        </Flex>
        <FormTextareaInput
          name="description"
          data-testid="project-description"
          labelConfig={{ text: t('modals.create_project.field_description_label') }}
          placeholder={t('modals.create_project.field_description_placeholder')!}
          warningThreshold={980}
          charactersLimit={1000}
        />
      </Flex>
    </Flex>
  )
}
