import { MayBeNull } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { Identifier } from 'dnd-core'
import { memo, useContext, useEffect, useRef, useState } from 'react'
import { useDrag, useDragLayer, useDrop } from 'react-dnd'

import { Flex } from 'components/common/flex/Flex'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { showAppPickerModal } from 'pages/project/components/canvas/components/appPikerModal/AppPickerModal'
import { Placeholder } from 'pages/project/components/canvas/components/placeholder/Placeholder'
import { useDragScrolling } from 'pages/project/components/canvas/hooks/useDragScrolling'
import { DragItem } from 'pages/project/components/canvas/linearCanvas/components/item/DragItem'
import { showAddEditActivityModal } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AddEditActivityModal'
import styles from 'pages/project/components/canvas/linearCanvas/components/phase/DragPhase.module.scss'
import { Phase } from 'pages/project/components/canvas/linearCanvas/components/phase/Phase'
import { LinearDispatchContext } from 'pages/project/components/canvas/linearCanvas/LinearProvider'
import {
  LinearPhase,
  DnDItem,
  DnDPhase,
  DragContainerType,
  DropPosition,
  getHzDropPosition,
  HzDropPosition,
} from 'pages/project/components/canvas/utils'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { ProcessType } from 'types/projects/projects'
import { PhaseItem } from 'types/projects/workflow'
import { isEqualEmails } from 'utils/common'

interface Props {
  tasks: PhaseItem[]
  column: LinearPhase
  index: number
  projectId: string
  selectedCanvas: ProcessType
  isDraggingDisabled: boolean
  isItemsDraggingDisabled: boolean
  isInactive?: boolean
}

export const DragPhase = memo(
  ({
    tasks,
    column,
    index,
    projectId,
    selectedCanvas,
    isDraggingDisabled,
    isItemsDraggingDisabled,
    isInactive,
  }: Props) => {
    const { dropOnColumn, moveColumn } = useContext(LinearDispatchContext)
    const phaseRef = useRef<HTMLDivElement>(null)
    const phaseItemRef = useRef<HTMLDivElement>(null)
    const {
      osContext: { userDetails },
    } = useOs()

    const { listenWindow, removeListenerWindow } = useDragScrolling()

    useEffect(() => {
      return () => removeListenerWindow()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { isPermitted } = useIsPermitted()
    const { hasRole } = useHasProjectRole()
    const isOwner = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)
    const isIAssignToThisPhase = isEqualEmails(column?.assignUser, userDetails.email)
    const [placeholderHeight, setPlaceholderHeight] = useState<MayBeNull<number>>(null)
    const [phaseHoverPosition, setPhaseHoverPosition] = useState<HzDropPosition | null>(null)
    const [phasePlaceholderHeight, setPhasePlaceholderHeight] = useState<MayBeNull<number>>(null)
    const isSomeItemDragging = useDragLayer(monitor => {
      if (monitor.getItemType() === DragContainerType.Phase) {
        return false
      }

      return monitor.isDragging()
    })

    const dropDisable =
      isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE) || isIAssignToThisPhase ? false : !isOwner

    const openAppPickerModal = (phaseId: string) => {
      showAppPickerModal({
        phaseId,
        projectId,
        selectedCanvas,
      })
    }
    const openActivityPickerModal = (phaseId: string) => {
      showAddEditActivityModal({
        projectId,
        phaseId,
      })
    }

    const [{ phaseHandlerId, isOverPhase }, dropPhase] = useDrop<
      DnDPhase,
      void,
      {
        phaseHandlerId: MayBeNull<Identifier>
        isOverPhase: boolean
      }
    >(
      {
        accept: DragContainerType.Phase,
        collect(monitor) {
          return {
            phaseHandlerId: monitor.getHandlerId(),
            isOverPhase: monitor.isOver({ shallow: true }),
          }
        },
        canDrop() {
          return !dropDisable
        },
        hover(dndItem: DnDPhase, monitor) {
          if (!phaseRef.current) return

          if (column.id === dndItem.id || !monitor.canDrop()) {
            setPhaseHoverPosition(null)
            return
          }

          const hoverBoundingRect = phaseRef.current?.getBoundingClientRect()
          const offset = monitor.getClientOffset()

          setPhaseHoverPosition(hoverBoundingRect && offset ? getHzDropPosition(hoverBoundingRect, offset) : null)
          setPhasePlaceholderHeight(dndItem.height ?? null)
        },
        drop(dndItem: DnDPhase, monitor) {
          removeListenerWindow()

          const didDrop = monitor.didDrop()
          if (didDrop) return

          if (!phaseRef.current) return

          //  drop on self
          if (column.id === dndItem.id) return

          const dropPosition = getHzDropPosition(phaseRef.current?.getBoundingClientRect(), monitor.getClientOffset()!)
          moveColumn(column.id, dndItem.id, dropPosition)
        },
      },
      [moveColumn, dropDisable],
    )

    const [{ isDragging: isPhaseDragging }, dragPhase, previewPhase] = useDrag(
      {
        type: DragContainerType.Phase,
        item: (): DnDPhase => {
          listenWindow()
          return { index, id: column.id, height: phaseRef.current?.getBoundingClientRect().height }
        },
        collect: monitor => ({
          isDragging: monitor.isDragging(),
        }),
        canDrag: () => !isInactive && isOwner && !isDraggingDisabled,
      },
      [isOwner, isDraggingDisabled],
    )
    previewPhase(dropPhase(phaseRef))

    const [{ isOverItem }, dropPhaseItem] = useDrop(
      () => ({
        accept: DragContainerType.Item,
        canDrop() {
          return !dropDisable
        },
        hover(dndItem: DnDItem, monitor) {
          if (!monitor.canDrop()) {
            setPhaseHoverPosition(null)
            return
          }

          setPlaceholderHeight(dndItem.height ?? null)
        },
        drop(dndItem: DnDItem, monitor) {
          // if dropPhaseItem was already handled
          const didDrop = monitor.didDrop()

          if (didDrop) return
          // can be dropped only at the bottom of the column (placeholder is currently visible only there).
          dropOnColumn(column.id, dndItem, DropPosition.Below)
        },
        collect: monitor => ({
          isOverItem: monitor.isOver({ shallow: true }),
        }),
      }),
      [dropOnColumn, column.id, dropDisable],
    )
    dropPhaseItem(phaseItemRef)

    return (
      <Flex
        ref={phaseRef}
        className={clsx(styles.dragPhaseWrapper, { [styles.isPhaseDragging]: isPhaseDragging })}
        data-testid={`drag-phase-container-${column.id}`}
      >
        {isOverPhase && phaseHoverPosition === HzDropPosition.Left && (
          <Placeholder
            className={clsx(styles.phasePlaceholder, styles.placeholderLeft)}
            height={phasePlaceholderHeight}
          />
        )}
        <Phase
          variant="primary"
          isEditable
          toggleAppPickerModal={openAppPickerModal}
          toggleActivityPickerModal={openActivityPickerModal}
          index={index}
          column={column}
          dragRef={dragPhase}
          dragHandlerId={phaseHandlerId}
          isDragging={isPhaseDragging}
          isInactive={isInactive}
          isDraggingDisabled={isDraggingDisabled}
        >
          <Flex
            direction="column"
            gap={12}
            className={clsx(styles.column, { [styles.isSomeItemDragging]: isSomeItemDragging })}
            ref={phaseItemRef}
            data-testid="phase-droppable-container"
          >
            {tasks.map((t, i) => (
              <DragItem
                projectId={projectId}
                task={t}
                key={t.id}
                index={i}
                isIAssignToThisPhase={isIAssignToThisPhase}
                isDraggingDisabled={isItemsDraggingDisabled}
                isInactive={isInactive}
              />
            ))}

            {isOverItem && <Placeholder height={placeholderHeight} />}
          </Flex>
        </Phase>
        {isOverPhase && phaseHoverPosition === HzDropPosition.Right && (
          <Placeholder
            className={clsx(styles.phasePlaceholder, styles.placeholderRight)}
            height={phasePlaceholderHeight}
          />
        )}
      </Flex>
    )
  },
)
