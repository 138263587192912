// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jzS6z{height:calc(100vh - var(--wpp-os-header-height))}`, "",{"version":3,"sources":["webpack://./src/auth/wrike/WrikeAuth.module.scss"],"names":[],"mappings":"AAAA,OACE,gDAAA","sourcesContent":[".container {\n  height: calc(100vh - var(--wpp-os-header-height));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `jzS6z`
};
export default ___CSS_LOADER_EXPORT___;
