import {
  WppActionButton,
  WppBanner,
  WppIconArchive,
  WppIconAvailableCheckmark,
  WppIconInfo,
  WppIconWarning,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useAuthLinkApi } from 'api/wrike/queries/useAuthLinkApi'
import { useWrikeRedirectUrl } from 'auth/wrike/utils'
import { useProject } from 'hooks/useProject'
import { useProjectRole } from 'hooks/useProjectRole'
import { useToast } from 'hooks/useToast'
import { showProjectEditModal } from 'pages/components/projectModal/EditProjectModal'
import styles from 'pages/project/components/banner/Banner.module.scss'
import { AdditionalProjectStatus, BannerStatus, ProjectStatus } from 'types/projects/projects'

interface StyleConfig {
  icon: ReactNode
  title: string
}

const bannerStyles: Record<Exclude<BannerStatus, ProjectStatus.ACTIVE>, StyleConfig> = {
  [ProjectStatus.COMPLETED]: {
    icon: <WppIconAvailableCheckmark color="var(--wpp-grey-color-000)" />,
    title: 'project.page.project_completed',
  },
  [ProjectStatus.ARCHIVED]: {
    icon: <WppIconArchive color="var(--wpp-grey-color-600)" />,
    title: 'project.page.project_archived',
  },
  [AdditionalProjectStatus.WORKSPACE_ERROR]: {
    icon: <WppIconWarning color="var(--wpp-grey-color-1000)" />,
    title: 'project.page.project_workspace',
  },
  [AdditionalProjectStatus.PROJECT_SETUP_PENDING]: {
    icon: <WppIconWarning color="var(--wpp-grey-color-1000)" />,
    title: 'project.page.project_setup_pending',
  },
  [AdditionalProjectStatus.WRIKE_NO_CONNECTED]: {
    icon: <WppIconInfo color="var(--wpp-grey-color-000)" />,
    title: 'project.page.project_wrike',
  },
  [AdditionalProjectStatus.WRIKE_NO_ACCESS]: {
    icon: <WppIconInfo color="var(--wpp-grey-color-000)" />,
    title: 'project.page.project_wrike_access',
  },
}

export const Banner = ({ status }: { status: BannerStatus }) => {
  const { t } = useTranslation()
  const { showToast } = useToast()
  const { project } = useProject()
  const isInactive = status !== ProjectStatus.ACTIVE
  const { isOwnerOrGlobalManage } = useProjectRole()

  const redirectUri = useWrikeRedirectUrl()
  const { refetch, isRefetching } = useAuthLinkApi({
    params: { redirectUri },
    enabled: false,
  })

  const handleAuth = useCallback(async () => {
    const { data, error } = await refetch()

    if (!error) {
      window.open(data?.data?.url, '_blank')
      return
    }

    console.error(error)
    showToast({
      type: 'error',
      message: t('common.generic_error'),
    })
  }, [refetch, showToast, t])

  return (
    <WppBanner
      show={isInactive}
      className={clsx(styles.statusBanner, {
        [styles.archivedBanner]: status === ProjectStatus.ARCHIVED,
        [styles.completedBanner]: status === ProjectStatus.COMPLETED,
        [styles.workspaceBanner]:
          status === AdditionalProjectStatus.WORKSPACE_ERROR ||
          status === AdditionalProjectStatus.PROJECT_SETUP_PENDING,
        [styles.wrikeBanner]:
          status === AdditionalProjectStatus.WRIKE_NO_CONNECTED || status === AdditionalProjectStatus.WRIKE_NO_ACCESS,
      })}
      data-testid={`project-status-banner-${status}`}
    >
      {isInactive && bannerStyles[status].icon}
      {isInactive && t(bannerStyles[status].title)}

      {status === AdditionalProjectStatus.WORKSPACE_ERROR && isOwnerOrGlobalManage && (
        <WppActionButton onClick={() => showProjectEditModal({ project: project })} variant="secondary" slot="actions">
          {t('project.page.update_project_settings')}
        </WppActionButton>
      )}

      {status === AdditionalProjectStatus.WRIKE_NO_CONNECTED && (
        <WppActionButton
          onClick={handleAuth}
          loading={isRefetching}
          variant="inverted"
          slot="actions"
          data-testid="project-status-banner-wrike-btn"
        >
          {t('project.page.connect_wrike')}
        </WppActionButton>
      )}
    </WppBanner>
  )
}
