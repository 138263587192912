import {
  WppActionButton,
  WppCard,
  WppIconGear,
  WppIconMore,
  WppMenuContext,
  WppListItem,
  WppTypography,
  WppDivider,
  WppIconApp,
  WppIconPlus,
  WppIconLink,
  WppIconDocument,
  WppIconRemoveCircle,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useDeleteActivityApi } from 'api/canvas/mutation/useDeleteActivityApi'
import { showDeleteModal } from 'components/common/deleteModal/DeleteModal'
import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useAssignMember } from 'hooks/useAssignMember'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import { showAddLinksModal } from 'pages/project/components/canvas/components/addLinksModal/AddLinksModal'
import { showAppPickerModal } from 'pages/project/components/canvas/components/appPikerModal/AppPickerModal'
import { AttachedFile } from 'pages/project/components/canvas/components/attachedFile/AttachedFile'
import { showAttachFilesModal } from 'pages/project/components/canvas/components/attachFilesModal/AttachFilesModal'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { ExternalLinkView } from 'pages/project/components/canvas/components/externalLink/ExternalLinkView'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson'
import { SelectDateInline } from 'pages/project/components/canvas/components/selectDateInline/SelectDateInline'
import { ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { SelectPersonInline } from 'pages/project/components/canvas/components/selectPersonInline/SelectPersonInline'
import { ShowMoreItems } from 'pages/project/components/canvas/components/showMoreItems/ShowMoreItems'
import styles from 'pages/project/components/canvas/fluidCanvas/components/fluidActivity/FluidEditableActivity.module.scss'
import { useUpdateAppMargin } from 'pages/project/components/canvas/fluidCanvas/components/fluidActivity/utils'
import { useUpdateItem } from 'pages/project/components/canvas/hooks/useUpdateItem'
import { showAddEditActivityModal } from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AddEditActivityModal'
import commonStyles from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AppActivityItem.module.scss'
import { MAX_LINKS_COUNT, useUiPartEnabled } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { TaskStatusChangeDropdown } from 'pages/project/components/tasks/components/changeStatus/TaskStatusChangeDropdown'
import { StatusText } from 'pages/project/components/tasks/components/statusText/StatusText'
import { queryClient } from 'providers/osQueryClient/utils'
import { ProjectPartKey } from 'types/projects/projectViewSettings'
import { TaskStatus } from 'types/projects/tasks'
import { ActivityItem, ExternalLink, PhaseItemType } from 'types/projects/workflow'
import { makeStringShorter } from 'utils/common'

interface Props {
  containerId: string
  activityItem: ActivityItem
  showAction: boolean
  isEditable: boolean
  isDisabled: boolean
  childrenCount: number
  variant?: 'primary' | 'secondary'
  sortedExternalLinks?: ExternalLink[]
  isIAssignToThisPhase?: boolean
  isInactive?: boolean
}

export const FluidEditableActivity = ({
  containerId,
  activityItem,
  showAction,
  isEditable,
  isDisabled,
  variant,
  sortedExternalLinks,
  childrenCount,
  isInactive,
}: Props) => {
  const { t } = useTranslation()
  const appsStarRef = useRef<HTMLDivElement>(null)
  const activityCardRef = useRef<HTMLWppCardElement>(null)
  const { name, startDate, endDate, id, task, files, description } = activityItem

  const { project } = useProject()

  const assignMember = useAssignMember(activityItem.assignUser)
  const isUIPartEnabled = useUiPartEnabled(isEditable)

  const { showToast } = useToast()
  const { mutateAsync: handleDeleteActivity } = useDeleteActivityApi()

  const { updateItem } = useUpdateItem({
    id: activityItem.id,
    type: PhaseItemType.Activity,
    name: activityItem.name,
    processType: project.processType,
    projectId: project.id,
  })

  const toggleAssignee = useCallback(
    async (newAssignee: ResponsibleUser) => {
      const isDeselecting = assignMember?.id === newAssignee.id
      await updateItem({ assignUser: isDeselecting ? null : newAssignee })
    },
    [assignMember, updateItem],
  )

  const editActivityModal = () => {
    showAddEditActivityModal({
      projectId: project.id,
      phaseId: 'fluid',
      activity: activityItem,
      isDisabled: isInactive,
    })
  }

  const handleDelete = async () => {
    try {
      await handleDeleteActivity({ id })
      showToast({
        type: 'success',
        message: t('project.canvas.toast.remove_activity', { query: makeStringShorter(activityItem.name) }),
      })

      queryClient.invalidateQueries([ApiQueryKeys.PROJECT_WORKFLOW_FLUID])
    } catch (e) {
      showToast({ type: 'error', message: t('project.canvas.toast.failed_operation_remove', { query: 'activity' }) })
      console.error(e)
    }
  }

  const childPlaceholders = useMemo(() => {
    const list: null[] = []
    list.length = childrenCount
    list.fill(null)
    return list
  }, [childrenCount])

  useUpdateAppMargin(containerId, activityCardRef, appsStarRef)

  return (
    <>
      <WppCard
        variant={variant}
        data-testid={`activity-card-${activityItem.id}`}
        size="s"
        ref={activityCardRef}
        className={clsx(commonStyles.itemContainer, styles.fluidItemContainer, {
          [commonStyles.disabledItem]: isDisabled || isInactive,
        })}
      >
        <Flex direction="column" className={styles.nameWrapper} gap={8}>
          <Flex align="center" justify="between">
            <Truncate lines={2} type="m-strong" title={name} data-testid="phase-item-name">
              {name}
            </Truncate>
            <Flex align="center" className={styles.alignSelf}>
              {showAction && (
                <WppMenuContext dropdownConfig={{ appendTo: () => document.body }}>
                  <WppActionButton slot="trigger-element" variant="secondary">
                    <WppIconMore slot="icon-start" direction="horizontal" />
                  </WppActionButton>

                  <Flex direction="column" className={styles.actionsGap}>
                    <WppListItem onWppChangeListItem={editActivityModal} data-testid="context-settings">
                      <WppIconGear slot="left" />
                      <WppTypography slot="label" type="s-body">
                        {t('project.canvas.settings')}
                      </WppTypography>
                    </WppListItem>
                    <div style={{ display: isInactive ? 'none' : 'block' }}>
                      <WppListItem
                        onWppChangeListItem={() =>
                          showDeleteModal({
                            title: t('modals.remove_activity.delete_item'),
                            subTitle: t('modals.remove_activity.delete_confirm')!,
                            deleteText: t('common.btn_delete')!,
                            onDelete: handleDelete,
                          })
                        }
                        data-testid="context-remove"
                      >
                        <WppIconRemoveCircle slot="left" />
                        <WppTypography slot="label" type="s-body">
                          {t('project.canvas.delete')}
                        </WppTypography>
                      </WppListItem>
                    </div>
                  </Flex>

                  {/* DO_NOT_DELETE: ternary operator coz error: Can't remove ReactNode. More like Tippy issue, remove node firstly */}
                  <div style={{ display: isInactive ? 'none' : 'block' }}>
                    <WppDivider />
                    <TaskStatusChangeDropdown
                      onChange={status => updateItem({ status })}
                      selectedStatus={task?.status}
                      showConfirm={false}
                    />
                  </div>
                </WppMenuContext>
              )}
            </Flex>
          </Flex>
          {description && (
            <Truncate
              lines={3}
              title={description}
              type="xs-body"
              data-testid="description"
              className={styles.greyColor800}
            >
              {description}
            </Truncate>
          )}

          <Flex direction="column" gap={8}>
            {(task?.status === TaskStatus.COMPLETED || task?.status === TaskStatus.ARCHIVED) && (
              <StatusText statusKey={task?.status} />
            )}
            {!isInactive && showAction ? (
              <Flex align="center" gap={4}>
                <SelectPersonInline selectedId={assignMember?.id} onChange={toggleAssignee}>
                  <ResponsiblePerson assignMember={assignMember} size="xs" />
                </SelectPersonInline>

                <SelectDateInline startDate={startDate} endDate={endDate} onChange={dates => updateItem({ dates })} />

                <div className={commonStyles.addMenuWrapper}>
                  <WppMenuContext data-testid="context-add">
                    <WppActionButton slot="trigger-element">
                      <WppIconPlus slot="icon-start" />
                      {t('project.canvas.btn_add_item')}
                    </WppActionButton>
                    <Flex direction="column" gap={4}>
                      <WppListItem
                        onWppChangeListItem={() =>
                          showAppPickerModal({
                            projectId: project.id,
                            selectedCanvas: project.processType,
                            activityId: id,
                          })
                        }
                        data-testid="context-add-app"
                      >
                        <WppIconApp slot="left" />
                        <p slot="label">{t('project.canvas.application')}</p>
                      </WppListItem>
                      <WppListItem
                        onWppChangeListItem={() =>
                          showAttachFilesModal({ projectId: project.id, activityId: activityItem.id })
                        }
                      >
                        <WppIconDocument slot="left" />
                        <p slot="label">{t('project.canvas.files')}</p>
                      </WppListItem>
                      <WppListItem
                        disabled={(sortedExternalLinks?.length ?? 0) >= MAX_LINKS_COUNT}
                        onWppChangeListItem={() =>
                          showAddLinksModal({
                            projectId: project.id,
                            activityId: activityItem.id,
                            existingCount: sortedExternalLinks?.length ?? 0,
                          })
                        }
                      >
                        <WppIconLink slot="left" />
                        <p slot="label">{t('project.canvas.link')}</p>
                      </WppListItem>
                    </Flex>
                  </WppMenuContext>
                </div>
              </Flex>
            ) : (
              <Flex gap={10} align="center">
                {isUIPartEnabled(ProjectPartKey.ResponsiblePersons) && (
                  <ResponsiblePerson assignMember={assignMember} data-testid="phase-item-assignee" size="xs" />
                )}
                {isUIPartEnabled(ProjectPartKey.Dates) && (
                  <Calendar startDate={startDate} endDate={endDate} data-testid="phase-item-dates" />
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
        <WppDivider className={commonStyles.divider} />
        {!!sortedExternalLinks?.length && (
          <Flex direction="column" gap={4} className={commonStyles.linksWrapper} data-testid="activity-links">
            <ShowMoreItems maxToShow={2}>
              {sortedExternalLinks.map(link => (
                <ExternalLinkView
                  activityId={activityItem.id}
                  link={link}
                  isDisabled={isDisabled}
                  key={link.id}
                  variant="secondary"
                  isInactive={isInactive}
                  linkActions={showAction}
                />
              ))}
            </ShowMoreItems>
          </Flex>
        )}

        {!!files?.length && (
          <Flex direction="column" gap={4} className={commonStyles.cardWrapper} data-testid="activity-files">
            <ShowMoreItems maxToShow={2}>
              {files.map(file => (
                <AttachedFile
                  file={file}
                  key={file.id}
                  activityId={activityItem.id}
                  isDisabled={isDisabled}
                  showAction={showAction}
                  isInFluid
                  variant="secondary"
                  isInactive={isInactive}
                />
              ))}
            </ShowMoreItems>
          </Flex>
        )}

        <div ref={appsStarRef} />

        {/* extending activity in such manner - to fit it size for all children AppNodes */}
        {childPlaceholders.map((_, idx) => (
          <div key={idx} id="" className={styles.innerAppPlaceholder} />
        ))}
      </WppCard>
    </>
  )
}
