import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { FormAutocomplete } from 'components/form/formAutocomplete/FormAutocomplete'
import { useProject } from 'hooks/useProject'
import styles from 'pages/project/components/canvas/components/selectPerson/SelectPerson.module.scss'
import { useSearchUsers } from 'pages/project/components/canvas/components/selectPerson/utils'

export const SelectPerson = ({ disabled }: { disabled?: boolean }): JSX.Element => {
  const { t } = useTranslation()
  const { watch } = useFormContext()
  const [assignUser] = watch('assignUser')
  const { project } = useProject()

  const { isUsersLoading, usersOptions, setUsersSearch } = useSearchUsers({
    projectId: project?.id,
    withWrike: !!project?.wrike?.isConnected,
  })

  const mapOtptions = useMemo(() => usersOptions.map(user => ({ ...user, caption: user.email })), [usersOptions])

  return (
    <Flex direction="column" gap={5}>
      <FormAutocomplete
        name="assignUser"
        placeholder={t('modals.settings.responsible_person_placeholder')!}
        labelConfig={{ text: t('modals.settings.responsible_person_label')! }}
        loading={isUsersLoading}
        options={mapOtptions}
        onWppSearchValueChange={event => setUsersSearch(event.detail)}
        disabled={disabled}
        data-testid="modal-phase-responsible-person"
      />
      {assignUser && !assignUser?.role && (
        <WppTypography type="xs-body" className={styles.note} data-testid="responsible-person-description">
          {t('modals.settings.responsible_person_description')}
        </WppTypography>
      )}
    </Flex>
  )
}
