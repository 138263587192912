import { WppTag } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { Truncate } from 'components/common/truncate/Truncate'
import { useAssignMember } from 'hooks/useAssignMember'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson'
import styles from 'pages/project/components/canvas/linearCanvas/components/item/linearActivity/AppActivityItem.module.scss'
import { ActivityItem } from 'types/projects/workflow'

interface Props {
  activity: ActivityItem
}

export const ActivityDetailsModalHeader = ({ activity }: Props) => {
  const assignMember = useAssignMember(activity?.assignUser)
  const { t } = useTranslation()

  return (
    <Flex direction="column" gap={12}>
      <Flex direction="column" gap={12}>
        {activity.description && (
          <Truncate
            lines={3}
            type="s-body"
            className={styles.greyColor1000}
            data-testid="description"
            title={activity.description}
          >
            {activity.description}
          </Truncate>
        )}
        <Flex gap={24} align="center">
          {assignMember && (
            <ResponsiblePerson assignMember={assignMember} size="xs" data-testid="application-item-assignee" showName />
          )}

          {(activity?.startDate || activity?.endDate) && (
            <Calendar startDate={activity?.startDate} endDate={activity.endDate} data-testid="application-item-dates" />
          )}

          <WppTag
            variant="neutral"
            label={t(`project.tasks.status.${activity.task.status}`)!}
            data-testid="app-task-status"
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
