import {
  WppCard,
  WppTypography,
  WppActionButton,
  WppIconMore,
  WppListItem,
  WppMenuContext,
  WppIconLink,
  WppIconEyeOn,
  WppDivider,
} from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'
import { format } from 'date-fns'
import { useCallback, useMemo, useRef } from 'react'
import { useDrag } from 'react-dnd'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import { useCopyToClipboard } from 'react-use'

import { TasksParams } from 'api/projects/fetchers/fetchProjectTasksApi'
import { Flex } from 'components/common/flex/Flex'
import { useAssignMember } from 'hooks/useAssignMember'
import { useIsPermitted } from 'hooks/useIsPermitted'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import { t } from 'i18next'
import { projectDateFormat } from 'pages/components/projectModal/utils'
import { Calendar } from 'pages/project/components/canvas/components/calendar/Calendar'
import { ResponsiblePerson } from 'pages/project/components/canvas/components/responsiblePerson/ResponsiblePerson'
import { SelectDateInline } from 'pages/project/components/canvas/components/selectDateInline/SelectDateInline'
import { getDate, ResponsibleUser } from 'pages/project/components/canvas/components/selectPerson/utils'
import { SelectPersonInline } from 'pages/project/components/canvas/components/selectPersonInline/SelectPersonInline'
import { updateMembersData } from 'pages/project/components/canvas/hooks/useUpdateItem'
import { invalidateCanvas } from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { DnDItem, DragContainerType } from 'pages/project/components/canvas/utils'
import { TaskStatusChangeDropdown } from 'pages/project/components/tasks/components/changeStatus/TaskStatusChangeDropdown'
import { useUpdateTaskItem } from 'pages/project/components/tasks/hooks/useUpdateTaskItem'
import styles from 'pages/project/components/tasks/Tasks.module.scss'
import { useHasProjectRole } from 'pages/project/hooks/useHasProjectRole'
import { DetailsModalType } from 'types/common/utils'
import { AppPermissions, ProjectRole } from 'types/permissions/permissions'
import { Task, TaskStatus } from 'types/projects/tasks'
import { PhaseItemType } from 'types/projects/workflow'
import { isEqualEmails } from 'utils/common'

interface Props {
  task: Task
  index: number
  isDraggingDisabled?: boolean
  updateStatus: (taskId: string, status: TaskStatus) => void
  projectId: string
  filters: TasksParams
}

export const TaskItem = ({ task, index, updateStatus, projectId, filters, isDraggingDisabled = false }: Props) => {
  const assignMember = useAssignMember(task.assignUser)
  const {
    osContext: { userDetails },
  } = useOs()

  const [, setSearchParams] = useSearchParams()
  const { isInactive } = useProject()
  const { hasRole } = useHasProjectRole()
  const { isPermitted } = useIsPermitted()

  const [, copyToClipboard] = useCopyToClipboard()
  const { showToast } = useToast()
  const { updateItem } = useUpdateTaskItem({ filters })

  const ref = useRef<HTMLDivElement>(null)

  const isOwnerOrGlobalManage = hasRole([ProjectRole.OWNER]) || isPermitted(AppPermissions.ORCHESTRATION_GLOBAL_MANAGE)

  const isMeAssignToThisPhase = useMemo(
    () => isEqualEmails(userDetails.email, task.location?.phase?.assignUser),
    [userDetails.email, task.location?.phase?.assignUser],
  )

  const isMeAssignToThisApp = useMemo(
    () => isEqualEmails(userDetails.email, assignMember?.email),
    [userDetails, assignMember],
  )

  const isMeAssignToThisActivity = useMemo(
    () => isEqualEmails(userDetails.email, task.location?.activity?.assignUser),
    [task.location?.activity?.assignUser, userDetails.email],
  )

  const showAction =
    (isOwnerOrGlobalManage || isMeAssignToThisApp || isMeAssignToThisPhase || isMeAssignToThisActivity) && !isInactive

  const dragDisabled = !showAction && !isDraggingDisabled

  const copyToClipboardAction = useCallback(
    (text: string) => {
      copyToClipboard(
        `${window.location.href}?${createSearchParams({ view: DetailsModalType.TASK_DETAILS_PREVIEW, id: text })}`,
      )
      showToast({ type: 'success', message: t('project.tasks.toast_copy_to_clipboard_success_message')! })
    },
    [copyToClipboard, showToast],
  )

  const openTaskModal = useCallback(
    (id: string) => setSearchParams({ view: DetailsModalType.TASK_DETAILS_PREVIEW, id }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const updateDates = useCallback(
    async (dates: Date[]) => {
      const stringDates = dates.map(date => format(date, projectDateFormat))

      const datesMapped = { ...getDate(stringDates) }

      await updateItem({ id: task.id, ...datesMapped })
      await invalidateCanvas()
    },
    [task.id, updateItem],
  )

  const updatePerson = useCallback(
    async (person: ResponsibleUser) => {
      const isDeselecting = task.assignUser === person.email

      updateMembersData({ projectId, assignUser: person })
      await updateItem({
        id: task.id,
        assignUser: isDeselecting ? null : person.email,
      })

      await invalidateCanvas(!person?.isMember)
    },
    [projectId, task.assignUser, task.id, updateItem],
  )

  const [{ isDragging }, drag] = useDrag(
    {
      type: DragContainerType.Item,
      item: (): DnDItem => {
        return {
          index,
          id: task.id,
          phaseId: task.status,
          type: PhaseItemType.Task,
          height: ref.current?.getBoundingClientRect().height,
        }
      },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: () => !dragDisabled,
    },
    [dragDisabled],
  )

  return (
    <WppCard
      size="s"
      variant="secondary"
      className={clsx(styles.itemContainer, {
        [styles.dragItem]: isDragging,
        [styles.grab]: !dragDisabled,
      })}
      key={task.id}
      data-testid="task-card"
      onClick={() => openTaskModal(task.id)}
      ref={drag}
    >
      <Flex direction="column" gap={4}>
        <WppTypography type="s-strong" data-testid="task-item-name" className={styles.textEllipsis}>
          {task.name}
        </WppTypography>

        {showAction ? (
          <Flex align="center">
            <Flex onClick={e => e.stopPropagation()} gap={4}>
              <SelectPersonInline selectedId={assignMember?.id} onChange={person => updatePerson(person)}>
                <ResponsiblePerson assignMember={assignMember} size="xs" data-testid="task-item-assignee" />
              </SelectPersonInline>

              <SelectDateInline
                key={task.id}
                startDate={task.startDate}
                endDate={task.endDate}
                data-testid="task-item-dates"
                onChange={dates => updateDates(dates)}
                defaultOverdueColor={task.status === TaskStatus.COMPLETED}
              />
            </Flex>
          </Flex>
        ) : (
          <Flex align="center" gap={10}>
            <div className={styles.disabledWrapper}>
              <ResponsiblePerson assignMember={assignMember} size="xs" data-testid="task-item-assignee" />
            </div>
            <div className={styles.disabledWrapper}>
              <Calendar startDate={task?.startDate} endDate={task.endDate} data-testid="task-item-dates" />
            </div>
          </Flex>
        )}
      </Flex>

      <Flex slot="actions" align="center" onClick={e => e.stopPropagation()}>
        <WppMenuContext dropdownConfig={{ appendTo: () => document.body }}>
          <WppActionButton slot="trigger-element" variant="secondary">
            <WppIconMore slot="icon-start" direction="horizontal" />
          </WppActionButton>

          <div data-testid="task-action-menu">
            <Flex direction="column" className={styles.actionsGap} gap={4}>
              <WppListItem onWppChangeListItem={() => openTaskModal(task.id)}>
                <WppIconEyeOn slot="left" />
                <WppTypography slot="label" type="s-body" data-testid="view-details">
                  {t('project.tasks.view_details_action')}
                </WppTypography>
              </WppListItem>
              <WppListItem
                onWppChangeListItem={() => {
                  copyToClipboardAction(task!.id)
                }}
              >
                <WppIconLink slot="left" />
                <WppTypography slot="label" type="s-body" data-testid="copy-link">
                  {t('project.tasks.copy_link_action')}
                </WppTypography>
              </WppListItem>
            </Flex>

            <div style={{ display: showAction ? 'block' : 'none' }}>
              <WppDivider />
              <TaskStatusChangeDropdown
                onChange={status => updateStatus(task.id, status)}
                selectedStatus={task?.status}
              />
            </div>
          </div>
        </WppMenuContext>
      </Flex>
    </WppCard>
  )
}
